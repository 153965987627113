import { styled } from '@mui/material'

export default styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    min-width: 100vw;
    background-color: #0c1220;
    color: white;
    text-align: center;
    span:nth-of-type(1){
        font-size: 60px;
        svg{
            margin-right: 15px;
            color: rgb(255,62,62);
            font-size: 40px;
        }
    }
    span:nth-of-type(2){
        font-size: 25px;
    }
`
