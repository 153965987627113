import React,{ useContext } from "react";
import PropTypes from 'prop-types'
import { MapContainer as Map } from 'react-leaflet'
import { ContextSetting } from '../../App'

const LeafletMap = ({ 
    centerPosition = {
        lat:25.03388,
        lng:121.45689
    },
    zoom = 13,
    childDom = <div />,
    getMapElement,
    reflashTime = +new Date()
}) => {
    const { convertCoordinate } = useContext(ContextSetting)
    const { lat,lng } = centerPosition
    const [x,y] = convertCoordinate({ lat,lng })
    return (
        <Map
            key={reflashTime}
            center={[lat - x, lng + y * 2]} 
            zoom={zoom} 
            zoomControl={false}
            whenCreated={getMapElement}
            zoomAnimation={true}
            markerZoomAnimation={true}
            attributionControl={false}
        >
            {childDom}
        </Map>
    )
}

LeafletMap.propTypes = {
    centerPosition: PropTypes.object,
    zoom: PropTypes.number,
    childDom: PropTypes.element,
    getMapElement: PropTypes.func,
    reflashTime: PropTypes.number
}

export default LeafletMap