import { styled } from "@mui/material";

export default styled('div')`
    .loading-outer{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(30,30,30,.5);
        backdrop-filter: blur(10px);
        opacity: 0;
        z-index: -1;
        transition: .5s ease;
        .loading{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(0deg);
            width: 180px;
            height: 180px;
            border-radius: 50%;
            border: 5px solid rgba(255,255,255,.7);
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            animation: loading-circle .8s linear infinite;
            box-shadow: inset 0 0 5px 0.5px rgba(255,255,255,.7), 0 0 2px 0.5px rgba(255,255,255,.7);
        }

        @keyframes loading-circle {
            100%{
                transform: translate(-50%,-50%) rotate(360deg);
            }
        }

        .loading-text{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: white;
            letter-spacing: 3px;
            font-size: 18px;
        }

        &.toggle{
            opacity: 1;
            z-index: 900;
        }
    }
`