import React from 'react'
import { Cancel } from '@mui/icons-material'
import { Container } from '.'
import { useLocation } from 'react-router-dom'

const Error404 = () => {
  const route = useLocation()
  
  return (
    <Container>
      <span><Cancel />Error {route.state ? route.state.errStatus : '404'}</span>
      <br />
      <span>{route.state ? route.state.errMessage : '操作錯誤'}</span>
    </Container>
  )
}

export default Error404
