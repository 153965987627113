import React,{ createContext } from 'react';
import L from 'leaflet'
import { Main } from './container'
import Proj4 from 'proj4'

export const ContextSetting = createContext({
    createMapIcon:(params = {
        imgUrl:'',
        iconSize:{ width:0,height:0 },
        className:'' || undefined
    }) => typeof L.Icon,
    convertCoordinate:(params = {
        lat:'',
        lng: ''
    }) => Proj4(new Proj4.Proj('EPSG:3857'),new Proj4.Proj('EPSG:4326'),[params.lat, params.lng])
})

const App = () => {
    const contextProps = {
        createMapIcon:(params = {
            imgUrl:'',
            iconSize:{ width:0,height:0 },
            className:''
        }) => {
            const { imgUrl,iconSize } = params
            return new L.Icon({
                iconUrl: imgUrl,
                iconSize: new L.Point(iconSize.width,iconSize.height),
                className: params?.className
            })
        },
        convertCoordinate:(params = {
            lat:'',
            lng: ''
        }) => Proj4(new Proj4.Proj('EPSG:3857'),new Proj4.Proj('EPSG:4326'),[params.lat, params.lng])
    }

    return (
        <ContextSetting.Provider value={contextProps}>
            <Main />
        </ContextSetting.Provider>
    )
}

export default App;
