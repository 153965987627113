import React,{ useContext } from "react";
import PropsType from 'prop-types'
import { Marker,Popup,Tooltip } from 'react-leaflet'
import { ContextSetting } from '../../App'

const MapMark = ({
    position = {
        lat: 25.03388,
        lng: 121.45689
    },
    icon,
    popUpContent = undefined,
    tooltipContent = undefined,
    getMarkPosition
}) => {
    const { lat,lng } = position;
    const { convertCoordinate } = useContext(ContextSetting)
    const [x,y] = convertCoordinate({ lat,lng })

    return(
        icon ?
        (
            <Marker
                position={[lat - x, lng + y * 2]} 
                icon={icon} 
                eventHandlers={{ click:() => getMarkPosition(position) }}
            >
                {popUpContent && (<Popup>{popUpContent}</Popup>)}
                {tooltipContent && (<Tooltip direction="bottom" offset={[0, 15]} opacity={1} permanent>{tooltipContent}</Tooltip>)}
            </Marker>
        ) : (
            <Marker 
                position={[lat + x, lng]}
                eventHandlers={{ click:() => getMarkPosition(position) }}
            >
                {popUpContent && (<Popup>{popUpContent}</Popup>)}
                {tooltipContent && (<Tooltip direction="bottom" offset={[0, 15]} opacity={1} permanent>{tooltipContent}</Tooltip>)}
            </Marker>
        )
    )
}

MapMark.propsType = {
    position: PropsType.object.isRequired,
    icon: PropsType.any,
    popUpContent: PropsType.string || PropsType.element,
    tooltipContent: PropsType.string || PropsType.element,
    getMarkPosition: PropsType.func,
}

export default MapMark
