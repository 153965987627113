import { styled } from "@mui/material";

export default styled('div')`
    .info-card-outer{
        background-color: rgba(0,0,0,.7) !important;
        color: #FFFFFF !important;
        box-shadow: 0 0 4px rgba(0,0,0,.7) !important;
        width: calc(100% - 2em);
        max-width: 400px;
        margin: unset;
        position: absolute;
        top: -100%;
        left: 0;
        z-index: 800;
        transition: .5s ease;
        overflow: hidden;
        &div{
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-top: 1px solid #009688;
            &:nth-of-type(1){
                border-top: none;
            }
        }
        .info-card{
            width: 94%;
            height: 100%;
            padding: 7px 12px;
            backdrop-filter: blur(2px);
            .info-card-header{
                padding: 0 !important;
                &div {
                    span:nth-of-type(2){
                        color: rgb(255,255,255,.54) !important;
                        white-space: pre-line;
                    }
                }
                .cancel-btn{
                    cursor: pointer;
                    user-select: none;
                    margin: 4px;
                    svg{
                        font-size: 30px;
                    }
                }
            }
            .info-card-content{
                padding: 0;
                line-height: 1.8;
                letter-spacing: 2px;
                h1{
                    line-height: initial;
                    margin: 0;
                }
                .go-link{
                    cursor: pointer;
                    user-select: none;
                }
                input{
                    font-size: 16px;
                    color: white;
                    width: 80%;
                    background-color: transparent;
                    outline: none;
                    border: none;
                }
            }
        }
        &.toggle{
            top: 0;
        }
        @media screen and (max-width:900px){
            &{
                z-index: 1100;
                margin: 16px;
            }
        }
    }
`