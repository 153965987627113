import React from 'react';
import ReactDOM from 'react-dom';
import styles from './globalStyle'
import { GlobalStyles } from "@mui/material";
import App from './App';
import { BrowserRouter } from 'react-router-dom'

const Root = () => (
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <GlobalStyles styles={styles} />
        <App />
    </BrowserRouter>
  </React.StrictMode>
);

ReactDOM.render(<Root />,document.getElementById('root'))
