import { styled } from "@mui/material";

export default styled('div')`
    .left-area{
        position: relative;
        height: 100vh;
        overflow: hidden;
        .leaflet-container{
            width: 100%;
            height: 100vh;
            .pin-tips{
                font-size: 16px;
                font-weight: bold;
            }
            .leaflet-tooltip{
                padding: 2px 5px 1px 5px;
                font-weight: bold;
                background-color: rgb(30,30,30);
                border: 1px solid rgb(30,30,30);
                color: white;
                &::before{
                    border-bottom-color:rgb(30,30,30);
                }
            }
        }
        .map-title,
        .map-title-rwd{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            transform: translate(30%, 41%);
            z-index: 700;
            h1{
                color:white;
                font-weight: bold;
                font-size: 30px;
                text-shadow:  1.6px 1.6px 3.2px black;
                letter-spacing: 10px;
                margin: 0;
                padding: 12px 0 12px 0;
            }
        }
        .map-title-rwd{
            background: linear-gradient(0deg,rgba(0,0,0,0),
            rgba(0,0,0,.2),
            rgba(0,0,0,.4),
            rgba(0,0,0,.6),
            rgba(0,0,0,.8),
            rgba(0,0,0,1));
            right: 0;
            transform: unset;
            text-align: center;
            h1{
                letter-spacing: 5px;
            }
        }
        .mark-img{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0) !important;
            width: 40px;
            height: 40px;
            z-index: 200;
            cursor: pointer;
            user-select: none;
            transition: .5s ease !important;
            &.toggle{
                width: 64px;
                height: 64px;
                transform: translate(-50%, 0) !important;
            }
        }
        .slider-outer {
            position:absolute;
            bottom: 0;
            right: 0;
            left: 0;
            padding: 0 35px;
            user-select: none;
            z-index: 806;
            background: linear-gradient(0deg,rgba(0,0,0,1),
            rgba(0,0,0,.8),
            rgba(0,0,0,.6),
            rgba(0,0,0,.4),
            rgba(0,0,0,.2),
            rgba(0,0,0,0));
            .MuiSlider-root{
                height: 14px;
                color: rgba(30,30,30,.7);
                margin-bottom: 25px;
                transition: .3s ease;
                @media screen and (max-width:650px){
                    &{
                        margin-bottom: 45px;
                    }
                }
                .MuiSlider-track{
                    border: none;
                }
                .MuiSlider-thumb{
                    height: 24px;
                    width: 24px;
                    background-color: white;
                    border: 2px solid rgba(0,0,0,.7);
                    z-index: 2;
                    &:focus, 
                    &:hover, 
                    &.Mui-active, 
                    &.Mui-focusVisible{
                        box-shadow: 'inherit';
                    }
                    &:before {
                        display: 'none';
                    }
                }
                .MuiSlider-markLabel{
                    color: white;
                    font-weight: bold;
                    top:36px;
                    @media screen and (max-width:650px){
                        &{
                            top:55px;
                            transform: translateX(-50%) rotate(90deg);
                            span{
                                display: inline-block;
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }
                .MuiSlider-valueLabel{
                    font-size: 13px;
                    background-color: rgb(30,30,30);
                    padding: 5px 10px 4px 10px;
                }
            }
            &.toggle{
                .MuiSlider-root{
                    color: rgba(255,255,255,.7);
                    .MuiSlider-valueLabel{
                        color: black;
                        background-color: white;
                    }
                }
            }
        }
        .dark-mode{
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            background-color:  rgba(255,255,255,.7);
            box-shadow: 0 0 2px 1px rgba(0,0,0,.7);
            padding: 3px 3.5px 1px 2.5px;
            margin: 8px;
            cursor: pointer;
            user-select: none;
            transition: .3s ease;
            z-index: 801;
            svg{
                color: rgb(255,225,0);
                font-size: 20px;
                transform: rotate(20deg);
            }
            &.toggle{
                background-color: rgba(30,30,30,.7);
                box-shadow: 0 0 2px 1px rgba(255,255,255,.7);
            }
            @media screen and (max-width:900px){
                &{
                    margin: 14px 8px 0 12px;
                    background-color: transparent;
                }
            }
        }
        .toggle-right-area-btn{
            position: absolute;
            right: 0;
            bottom: 8%;
            transform: translateX(-10%);
            background-color: rgba(30,30,30,.7);
            color: white;
            padding: 5px 12px;
            border-radius: 5px;
            cursor: pointer;
            user-select: none;
            z-index: 801;
            @media screen and (max-width:900px){
                &{
                    bottom: 14%;
                }
            }
        }
        .right-area,
        .right-area-rwd{
            color: white;
            display: flex;
            flex-direction: column;
            position: absolute;
            pointer-events: none;
            top: 0;
            right: 0;
            bottom: 11.5%;
            width: 777px;
            margin: 5px;
            z-index: 1000;
            right: -375px;
            transition: .5s ease;
            .school-info-list{
                pointer-events: auto;
                position: absolute;
                background-color: rgba(0,0,0,.7);
                top: 0;
                right: 0;
                bottom: 0;
                width: 365px;
                border-radius: 5px;
                overflow: hidden;
                backdrop-filter: blur(2px);
                box-shadow: 0 0 4px 0 rgba(0,0,0,.7);
                .school-header-info {
                    font-size: 30px;
                    text-align: center;
                    padding: 10px;
                    border-radius: 5px;
                    margin: 12px;
                    letter-spacing: 5px;
                }
                .school-body-list-outer{
                    overflow-y: scroll;
                    overflow-x: hidden;
                    height: 79.5vh;
                    .school-body-list{
                        padding: 0 0 12px 5px;
                        .list-item{
                            box-shadow: inset 0 0 3px 1px rgba(255,255,255,.7);
                            padding: 10px 12px;
                            background-color: unset;
                            transition: .7s ease;
                            user-select: none;
                            .list-item-title{
                                font-size: 25px;
                                letter-spacing: 2px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 1;
                                -webkit-box-orient: vertical;
                            }
                            &:first-of-type{
                                border-radius: 5px 5px 0 0;
                            }
                            &:last-of-type{
                                border-radius: 0 0 5px 5px;
                            }
                            .list-item-content{
                                margin-top: 5px;
                                padding-top: 5px;
                                font-size: 18px;
                                border-top: 3px solid rgba(255, 255, 255,.5);
                                line-height: 25px;
                                span{
                                    letter-spacing: 3px;
                                }
                            }
                        }
                        @media screen and (max-width:900px){
                            padding: 0 5px 12px 5px;
                        }
                    }
                    &::-webkit-scrollbar {
                        width: 5px;
                        background-color: transparent;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 20px;
                        background-color: rgba(255, 255, 255, 0.7);
                    }
                    @media screen and (max-width:900px){
                        &{
                            height: 90.5vh;
                        }
                    }
                }
                @media screen and (max-width:900px){
                    &{
                        left: 0;
                        width: unset;
                        backdrop-filter:unset;
                    }
                }
            }
            .in-box{
                pointer-events: auto;
            }
            &.toggle{
                right: 0;
            }
        }

        .right-area-rwd{
            position: fixed;
            top: 100%;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1200;
            pointer-events: auto;
            background-color: transparent;
            margin: unset;
            width: 100%;
            height: 100%;
            border-radius: unset;
            overflow: unset;
            backdrop-filter: blur(2px);
            transition: .5s ease;
            .close-list-btn{
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(-50%, 45%);
                svg{
                    font-size: 40px;
                }
            }
            &.rwd-toggle{
                top: 0;
            }
        }
    }
`