import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardHeader } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import { Container } from '.'

const FloatCard = ({
  // param
  isShow = false,
  onClose = () => undefined,
  cardData = []
}) => {
  return (
    <Container>
        <Card className={isShow ? 'info-card-outer toggle' : 'info-card-outer'}>
            {cardData.map((item, idx) => (
            <div className='info-card' key={idx}>
                <CardHeader
                className='info-card-header'
                title={item.title}
                subheader={item.subtitle}
                action={
                    idx === 0 && (
                        <div className='cancel-btn'>
                            <Cancel onClick={onClose} />
                        </div>
                    )
                }
                />
                {'content' in item && <div className='info-card-content'>{item.content}</div>}
            </div>
            ))}
        </Card>
    </Container>
  )
}

FloatCard.propTypes = {
    isShow: PropTypes.bool,
    onClose: PropTypes.func,
    cardData: PropTypes.array
}

export default FloatCard
