import React, { useEffect,useContext, useState,useRef } from 'react'
import 'leaflet/dist/leaflet.css'
import { TileLayer } from 'react-leaflet'
import { useNavigate } from 'react-router-dom'
import { Slider } from '@mui/material'
import { Brightness2, Cancel } from '@mui/icons-material'
import { LeafletMap,MapMark,FloatCard,Loading } from '../../component'
import { ContextSetting } from '../../App'
import { Container } from '.'
import fetch from '../../utils/fetch'

const LessonStopMap = () => {
    const { createMapIcon,convertCoordinate } = useContext(ContextSetting)
    const router = useNavigate()
    const dateMarks = useRef([])
    const mapElement = useRef(null)
    const [{
        center,
        darkMode,
        infoCardIsShow,
        lessonStopSchoolData,
        lessonStopSchoolFilterData,
        cardData,
        currentSelectDate,
        startDate,
        isLoading,
        rwdStatus,
        toggleRwdRightArea,
        reflashTime
    }, setInitState] = useState({
        center: { lat:25.03388,lng:121.45689 },
        darkMode: false,
        infoCardIsShow: false,
        lessonStopSchoolData: [],
        lessonStopSchoolFilterData: [],
        cardData: [],
        currentSelectDate: 0,
        startDate: -1,
        isLoading: true,
        rwdStatus: window.innerWidth <= 900,
        toggleRwdRightArea: false,
        reflashTime: +new Date(),
    })

    const newPin = createMapIcon({
        imgUrl: `${process.env.PUBLIC_URL}/images/school-mark.png`,
        iconSize: { width: 32,height: 32 },
        className:'school-pin'
    })

    const formatDateTime = (params,fomatType) => {
        const paramsTemp = typeof params === 'number' ? params : +new Date(params)
        const [year,month,date] = new Date(paramsTemp + (8 * 60 * 60 * 1000)).toJSON().split('T')[0].split('-')
        return fomatType.toLocaleLowerCase().replace('yyyy',year).replace('mm',month).replace('dd',date)
    }

    const toggleDarkMode = () => setInitState(prevState => ({ ...prevState, darkMode: !darkMode, reflashTime:+new Date() }))

    const changeDate = ({ target: { value } }) => {
        const newDateTime = formatDateTime(+new Date(startDate + Number(value) * 24 * 60 * 60 * 1000),'yyyy/MM/dd')

        setInitState(prevState => ({
          ...prevState,
          currentSelectDate: value,
          lessonStopSchoolFilterData: prevState.lessonStopSchoolData.filter(item => item.begin_date === newDateTime),
          infoCardIsShow: false,
        }))
        mapElement.current.flyTo([25.03388,121.45689],11)
    }

    const handleMakerFocus = ({ lat, lng }) => {
        const filterItem = handleUpdateCardInfo({ lat, lng })

        if(filterItem.length > 0){
            const [x,y] = convertCoordinate({ lat,lng })
            mapElement.current.flyTo([lat - x,lng + y * 2])

            setInitState(prevState => ({
                ...prevState,
                infoCardIsShow: true,
                cardData: filterItem,
            }))
        }
    }

    const handleUpdateCardInfo = ({ lat, lng }) => {
        const beginDate = formatDateTime(+new Date(startDate + Number(currentSelectDate) * 24 * 60 * 60 * 1000),'yyyy/MM/dd')
        return lessonStopSchoolData.filter(filterItem => filterItem.latitude === lat && filterItem.longitude === lng && filterItem.begin_date === beginDate).map(item => ({
          schoolId: item.school_name,
          title: item.school_name,
          subtitle: '',
          content: <>
                    <div>停課開始日期：{item.begin_date || '暫無資訊'}</div>
                    <div>停課結束日期：{item.end_date || '暫無資訊'}</div>
                    <div>停課類型：{item.stop_kind || '暫無資訊'}</div>
                    <div>官方公布資料圖片連結：{item.img_url ? <span className='go-link' onClick={() => window.open(item.img_url)}>點我</span> : '無'}</div>
                    <div>備註：<input readOnly value={item.remark} /></div>
                   </>
        }))
    }

    useEffect(() => {
        fetch.get(process.env.REACT_APP_API)
        .then(res => {
            const obj = res.data.sort((front, end) => +new Date(end.begin_date) - +new Date(front.begin_date))[0]
            const dataDate = +new Date(obj.begin_date) - 12 * 24 * 60 * 60 * 1000
            const startDateToLocalString = formatDateTime(+new Date(dataDate + currentSelectDate * 24 * 60 * 60 * 1000),'yyyy/MM/dd')

            dateMarks.current = Array.from({ length: 13 }, (_, num) => {
                const timeStempForDay = dataDate + (num * 24 * 60 * 60 * 1000)
                const [month,date] = formatDateTime(timeStempForDay,'MM/dd').split('/')
                return {
                    value: num,
                    label: window.innerWidth <= 650 ? (<>
                        <span>{month}</span>
                        <span>‧</span>
                        <span>{date}</span>
                    </>) : `${month} / ${date}`
                }
            })

            setInitState(prevState => ({
                ...prevState,
                lessonStopSchoolData: res.data,
                lessonStopSchoolFilterData: res.data.filter(filterItem => filterItem.begin_date === startDateToLocalString),
                startDate: dataDate,
                isLoading: false,
            }))
        })
        .catch(err => router({
            pathname: '/error'
        },{
            state:{
                errStatus: err.status,
                errMessage: '網路錯誤或服務器錯誤'
            }
        }))
    }, [])

    return(
        <Container>
            <div className='left-area'>
                <div className={rwdStatus ? 'map-title-rwd' : 'map-title' }>
                    <h1>停課地圖</h1>
                </div>
                <LeafletMap
                    centerPosition={center}
                    zoom={11}
                    getMapElement={(maps) => mapElement.current = maps}
                    reflashTime={reflashTime}
                    childDom={
                        <>
                            <TileLayer
                                url={darkMode ? "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png" : "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"}
                            />
                            {lessonStopSchoolData.length > 0 && lessonStopSchoolFilterData.map((item, index) => (
                                <MapMark
                                    key={index}
                                    position={{
                                        lat: item.latitude,
                                        lng: item.longitude
                                    }}
                                    icon={newPin}
                                    getMarkPosition={({ lat,lng }) => handleMakerFocus({ lat,lng })}
                                    tooltipContent={`${item.school_name.indexOf(item.city) === -1 ? item.city || '' : ''}${item.school_name || ''}`}
                                />
                                ))}
                        </>
                    }
                >
                </LeafletMap>
                <div className={darkMode ? 'slider-outer toggle' : 'slider-outer'}>
                    <Slider
                        value={currentSelectDate}
                        onChange={changeDate}
                        valueLabelFormat={value => formatDateTime(+new Date(startDate + Number(value) * 24 * 60 * 60 * 1000),'MM 月 dd 日')}
                        min={0}
                        max={12}
                        valueLabelDisplay="auto"
                        marks={dateMarks.current}
                    />
                </div>
                <div
                    className={darkMode ? 'dark-mode toggle' : 'dark-mode'}
                    onClick={toggleDarkMode}
                >
                    <Brightness2 />
                </div>
                <div
                    className="toggle-right-area-btn"
                    onClick={() => setInitState(prevState => ({ ...prevState, toggleRwdRightArea: !toggleRwdRightArea }))}
                >最新學校停課列表</div>
                <div className={(() => {
                    if(!rwdStatus){
                        return toggleRwdRightArea ? 'right-area toggle' : 'right-area'
                    } else {
                        return toggleRwdRightArea ? 'right-area-rwd rwd-toggle' : 'right-area-rwd' 
                    }
                })()}>
                    <div className="school-info-list">
                        <div className="school-header-info">最新學校停課資訊</div>
                        <div className="school-body-list-outer">
                            <div className="school-body-list">
                                {lessonStopSchoolData.length > 0 ? Array.from({ length: 10 }, (_, num) => {
                                    const sortData = lessonStopSchoolData.sort((front, end) => +new Date(end.begin_date) - +new Date(front.begin_date))
                                    const obj = sortData[num]
                                    return (
                                    <div key={num} className='list-item'>
                                        <div className='list-item-title'>{obj.city}{obj.school_name}</div>
                                        <div className='list-item-content'>
                                        <div>停課開始日期：<span>{obj.begin_date}</span></div>
                                        <div>停課結束日期：<span>{obj.end_date}</span></div>
                                        </div>
                                    </div>
                                    )
                                }) : (
                                    <div>no data</div>
                                )}
                            </div>
                        </div>
                        {rwdStatus && (
                            <div
                                className="close-list-btn"
                                onClick={() => setInitState(prevState => ({ ...prevState, toggleRwdRightArea: false }))}
                            >
                                <Cancel />
                            </div>
                        )}
                    </div>
                    {!rwdStatus && (
                        <div className='in-box'>
                            <FloatCard
                                isShow={infoCardIsShow}
                                onClose={() => {
                                    setInitState(prevState => ({
                                        ...prevState,
                                        infoCardIsShow: false
                                    }))
                                }}
                                cardData={cardData}
                            />
                        </div>
                    )}
                </div>
                {rwdStatus && (
                    <FloatCard
                        isShow={infoCardIsShow}
                        onClose={() => {
                            setInitState(prevState => ({
                                ...prevState,
                                infoCardIsShow: false
                            }))
                        }}
                        cardData={cardData}
                    />
                )}
            </div>
            <Loading 
                isLoading={isLoading}
            />
        </Container>
    )
}

export default LessonStopMap