class FetchClass {
    constructor(){
        this.baseUrl = ''
        this.baseHeaders = {}
    }

    static async fetchSetting(url,settingParams) { 
        //#region 參數設定
        /**
         * @param {string} method
         * @param {object} header
         * @param {string} contentType
         * @param {object} data
         */
        //#endregion

        const settings = {};
        const { method, headers, contentType, data } = settingParams;

        settings.method = method;
        const urlTemp = this.baseUrl ? `${this.baseUrl}${url}` : url;

        if (this.baseHeaders || headers) {
            settings.headers = this.baseHeaders || headers;
        }

        if (data) {
            settings.headers = this.baseHeaders || { "Content-Type": contentType };
            settings.body = JSON.stringify(data);
        }

        if ((this.baseHeaders || headers) && data) {
            settings.headers = this.baseHeaders || { ...headers };
            settings.body = JSON.stringify(data);
        };

        const res = await fetch(urlTemp, settings).then(res => res);

        return new Promise((resolve,reject) => {
            if (res.status >= 200 && res.status < 300) {
                res.json().then(resItem => resolve({
                    bodyUsed: res.bodyUsed,
                    headers: res.headers,
                    ok: res.ok,
                    redirected: res.redirected,
                    status: res.status,
                    statusText: res.statusText,
                    type: res.type,
                    url:res.url,
                    data:resItem
                }));
            }
            else {
                reject({
                    bodyUsed: res.bodyUsed,
                    headers: res.headers,
                    ok: res.ok,
                    redirected: res.redirected,
                    status: res.status,
                    statusText: res.statusText,
                    type: res.type,
                    url:res.url,
                });
            };
        })
    };

    static createBase({ baseUrl,baseHeaders }){
        //#region
        /** 參數設定
         * @param {string} baseUrl
         * @param {object} baseHeaders
         */
        //#endregion
        this.baseUrl = baseUrl
        this.baseHeaders = baseHeaders
    }

    static get(url,setting){
        return this.fetchSetting(url,{ method: 'get', ...setting })
    }
}

FetchClass.createBase({
    baseHeaders: {
        'Content-Type':'application/json'
    }
})

export default FetchClass