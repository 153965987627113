import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '.'

const Loading = ({
    isLoading = false
}) => {
    return (
        <Container>
            <div className={isLoading ? 'loading-outer toggle' : 'loading-outer'}>
                <div className='loading'></div>
                <div className='loading-text'>Loading</div>
            </div>
        </Container>
    )
}

Loading.propTypes = {
    isLoading: PropTypes.bool
}

export default Loading