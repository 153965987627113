import React from 'react'
import { Routes,Route,Navigate } from 'react-router-dom'
import { LessonStopMap,Error404 } from '..'

const Main = () => {
    return (
        <Routes>
            <Route path='/' element={<Navigate replace={true} to={'/lesson_stop_map'} />} />
            <Route path='lesson_stop_map' element={<LessonStopMap />} />
            <Route path='error' element={<Error404 />} />
            <Route path='*' element={<Navigate replace={true} to='/error' />} />
        </Routes>
    )
}

export default Main